<template>
    <v-navigation-drawer
            v-model="Sidebar_drawer"
            :dark="SidebarColor !== 'white'"
            :color="SidebarColor"
            mobile-breakpoint="960"
            clipped
            mini-variant-width="70"
            :expand-on-hover="expandOnHover"
            app
            id="main-sidebar"
    >
        <!---User Area -->
        <v-list-item two-line class="profile-bg">
            <v-list-item-avatar color="grey darken-1">
                <v-img :src="user.pictures[0].url" v-if="getStatusLogin"></v-img>
                <v-icon x-large v-else>mdi-account</v-icon>
            </v-list-item-avatar>
            
            <v-list-item-content>
                <v-list-item-title v-show="getStatusLogin">{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle class="caption">{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <!---User Area -->
        
        <v-divider></v-divider>
    
        <v-list dense>
            <v-list-group
                    v-for="(item, index) in items"
                    :key="index"
                    :prepend-icon="item.icon"
                    v-model="item.active"
                    no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>
            
                <v-list-item link
                             v-for="(child, idx) in item.children"
                             :key="idx"
                             :to="child.link">
                
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                
                    <v-list-item-icon class="sicon">
                        <v-icon color="white">{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>

    </v-navigation-drawer>
</template>

<script>
    import { mapState } from "vuex";
    import VerticalSidebarItems from "./VerticalSidebarItems";

    export default {
        name: "VerticalSidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            items: VerticalSidebarItems,
            user: null,
        }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                },
            },
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },
        watch: {
            "$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            },
        },
        methods: { },
        mounted() {
            this.user = this.$store.getters.getUser;
        }
    };
</script>

<style lang="scss">
    #main-sidebar {
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        
        .v-navigation-drawer__border {
            display: none;
        }
        .v-list {
            padding: 8px 8px;
        }
        .v-list-item {
            min-height: 35px;
            &__icon--text,
            &__icon:first-child {
                justify-content: center;
                text-align: center;
                width: 20px;
            }
        }
        .v-list-item__icon i {
            width: 20px;
        }
        .icon-size .v-list-group__items i {
            width: 16px;
            font-size: 16px;
        }
        .profile-bg {
            &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
                opacity: 1;
            }
            .v-avatar {
                padding: 15px 0;
            }
        }
        .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
</style>