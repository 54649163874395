<template>
    <v-app-bar
        dense
        app
        clipped-left
        clipped-right
        :color="navbarColor"
        :dark="navbarColor !== 'white'">
        <div @click="showhideLogo">
            <v-app-bar-nav-icon
                @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
            />
        </div>
        
        <!---Dark Logo part -->
        <v-toolbar-title
            class="align-center d-flex logo-width"
            :class="`${showLogo ? 'logo-width' : ''}`">
            <span class="logo-icon pl-2" v-show="false">
                <img width="120" src="@/assets/images/logo-bhc.png" class="mt-2"/>
            </span>
        </v-toolbar-title>
        
        <v-spacer/>
        
        <v-btn
            rounded color="primary"
            depressed plain icon large
            @click="openPDF">
            <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-btn rounded color="primary" depressed plain icon large v-on:click="doLogout">
            <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
    
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    
    </v-app-bar>
</template>

<script>
    // Utilities
    import {mapState, mapMutations} from "vuex";
    import loginService from '@/providers/LoginService';
    import axios from 'axios';
    
    export default {
        name: "VerticalHeader",
        props: {
            value: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            snackBar: false,
            snackText: '',
            showLogo: true,
            showSearch: false,
            srcPdf: null,
            fav: true
        }),
        computed: {
            ...mapState(["navbarColor", "Sidebar_drawer"]),
        },
        methods: {
            ...mapMutations({
                setSidebarDrawer: "SET_SIDEBAR_DRAWER",
            }),
            showhideLogo: function () {
                this.showLogo = !this.showLogo;
            },
            searchbox: function () {
                this.showSearch = !this.showSearch;
            },
            doLogout() {
                loginService.logoutUser();
            },
            openPDF() {
                let baseURL = this.$store.getters.getBaseURL;
                // let urlPdf = `${baseURL}/view-pdf`;
                // window.open(urlPdf, "_blank");
                axios.get(`${baseURL}/show-pdf`)
                    .then(response => {
                        console.log('response: ', response);
                        if (response.data.success) {
                            this.srcPdf = response.data.url;
                            window.open(this.srcPdf, "_blank");
                            this.snackBar = false;
                        } else {
                            this.snackText = 'Un error impidio abrir el documento de ayuda!';
                            this.snackBar = true;
                        }
                    })
                    .catch(error => {
                            console.log('Error: ', error);
                            this.snackText = 'Un error del lado del servidor. Contacte al administrador!';
                            this.snackBar = true;
                        }
                    );
            }
        }
    };
</script>

<style lang="scss">
    .v-application .theme--dark.white .theme--dark.v-btn.v-btn--icon {
        color: #455a64 !important; /*$font-color*/
    }
    
    .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined),
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1); /* $box-shadow;*/
    }
    
    .v-btn--icon.v-size--default .v-icon {
        width: 20px;
        font-size: 20px;
    }
    
    .hidelogo {
        display: none;
    }
    
    .searchinput {
        position: absolute;
        width: 100%;
        margin: 0;
        left: 0;
        z-index: 10;
        padding: 0 15px;
    }
    
    .descpart {
        max-width: 220px;
    }
</style>
