/****************************************************/
// Capa de Servicios para el modulo de Autenticacion.

import axios from "axios";
import store from "@/store/store.js";
import router from "@/router";

export default new class {

    #baseUrl = null; //"https://ws-bhc.qa.idooproject.com/api/auth";
    data = null;
    user = null;
    status = null;
    error_message = "";

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL + "/auth";
    }

    // { headers: {
    //     "Content-Type": "application/json"
    //     Authorization: "Bearer " + apiToken,
    //      'Access-Control-Allow-Origin': '*'
    // }

    async loginUser(passport) {
        let result = {
            valid: false,
            status: 0,
            messageText: '',
            token: ''
        };
        // console.log('Credenciales:', passport);
        try {
            let response = await axios.post(this.#baseUrl + "/login", passport, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }
            );
            this.data = response.data;
            // console.log('Login result:', this.data);
            if (this.data.success) {
                // this.redirectToDashboard();
                result.valid = response.data.success;
                result.status = 200;
                result.messageText = 'Usuario autorizado...';
                result.token = this.data.access_token;
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                this.error_message = err.response.data.message;
                // console.log('Error message:', this.error_message);
            }
            result.valid = err.response.data.success;
            result.status = err.response.status;
            result.messageText = err.response.data.message;
            result.token = '';
        }
        return result;
    }

    redirectToDashboard(token) {
        axios.get(this.#baseUrl + "/user", {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: 'Bearer ' + this.data.access_token,
            }
        })
            .then(response => {
                this.user = response.data;
                // console.log('Login User:', this.user);
                if (this.user) {
                    store.commit('SetUser', this.user);
                    store.commit('SetIslogin', true);
                    store.commit('SetToken', token);
                    /*store.state.user = this.user;
                    store.state.isLogin = true;
                    store.state.token = token;*/
                    // console.log('Datos Almacenados en el Store:', store.state);
                    router.push({path: "/admin-page"});
                } else {
                    // console.log('Error response:', response);
                    this.error_message = response.data.status;
                    /*result.valid = false;
                    result.status = response.data.status;
                    result.messageText = response.data.message;*/
                    // console.log('Error message:', this.error_message);
                }
            });
    }

    logoutUser() {
        // console.log('Datos del store: ', store.state);
        axios.get(this.#baseUrl + "/logout", {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: 'Bearer ' + store.getters.getToken
                }
            }
        )
            .then(response => {
                    this.status = response.data.status;
                    // console.log('response:', response);
                    if (this.status === "success") {
                        store.dispatch('clearStateLogin');
                        store.commit('resetStateLogin');
                        router.push({path: "/homepage"});
                    }
                }
            )
            .catch(e => {
                if (e.response && e.response.status === 400) {
                    this.error_message = e.response.data.error;
                    // console.log('Error message:', this.error_message);
                }
            });
    }

}

/*
    loginUser(passport) {
        let result = {
            valid: false,
            status: 0,
            messageText: ''
        };
        console.log('Credenciales:', passport);
        axios.post(this.#baseUrl + "/login", passport, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        )
            .then(response => {
                this.data = response.data;
                console.log('Login result:', this.data);
                if (this.data.success) {
                    axios.get(this.#baseUrl + "/user", {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            Authorization: 'Bearer ' + this.data.access_token,
                        }
                    })
                        .then(response => {
                            this.user = response.data;
                            console.log('Login User:', this.user);
                            if (this.user) {
                                store.state.user = this.user;
                                store.state.isLogin = true;
                                store.state.token = this.data.access_token;
                                console.log('Datos Almacenados en el Store:', store.state);
                                router.push({path: "/admin-page/dashboard"});
                                result.valid = true;
                                result.status = 200;
                                result.messageText = 'Usuario autorizado';
                            } else {
                                console.log('Error response:', response);
                                this.error_message = response.data.status;
                                result.valid = false;
                                result.status = response.data.status;
                                result.messageText = response.data.message;
                                // console.log('Error message:', this.error_message);
                            }
                        })
                }
            })
            .catch(err => {
                console.log('Error err.response:', err.response);
                console.log('Error err.message:', err.message);
                console.log('Error err.response.status:', err.response.status); // 401
                console.log('Error err.response.data:', err.response.data);
                console.log('Error err.response.data.message:', err.response.data.message);

                if (err.response && err.response.status === 401) {
                    this.error_message = err.response.data.message;
                    console.log('Error message:', this.error_message);
                }
                result.valid = false;
                result.status = err.response.status;
                result.messageText = err.response.data.message;
            });
        return result;
    }

*/
