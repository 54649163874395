export default [
    {
        icon: 'mdi-account-multiple-outline',
        title: 'Administración',
        active: false,
        children: [
            {title: 'Organización', icon: 'mdi-cog-outline', link: '/company'},
            {title: 'Usuarios', icon: 'mdi-account-circle', link: '/admin-user'},
            {title: 'Comentarios', icon: 'mdi-comment-text-outline', link: '/mod-comments'},
            {title: 'Contactos', icon: 'mdi-account-multiple', link: '/mod-contacts'},
            {title: 'Unete a BHC', icon: 'mdi-link-variant', link: '/mod-jointobhc'}
        ]
    },
    {
        icon: 'mdi-animation',
        title: 'Nomencladores',
        active: false,
        children: [
            {title: 'Ciudades', icon: 'mdi-city', link: '/nom-cities'},
            {title: 'Asociaciones', icon: 'mdi-login-variant', link: '/nom-associations'},
            {title: 'Certificaciones', icon: 'mdi-tag', link: '/nom-certifications'},
            {title: 'Especialidades', icon: 'mdi-animation', link: '/nom-specialties'},
            {title: 'SubEspecialidades', icon: 'mdi-apps', link: '/nom-subspecialties'},
            {title: 'Servicios Extras', icon: 'mdi-cards-variant', link: '/nom-extraservices'},
            {title: 'Categorías-Blogs', icon: 'mdi-format-list-checks', link: '/nom-blogscategories'}
        ],
    },
    { /*mdi-view-module*/
        icon: 'mdi-apps',
        title: 'Módulos',
        active: false,
        children: [
            {title: 'Hospitales', icon: 'mdi-hospital-building', link: '/mod-hospitals'},
            {title: 'Clínicas', icon: 'mdi-hospital-box', link: '/mod-clinics'},
            {title: 'Médicos', icon: 'mdi-doctor', link: '/mod-doctors'},
            {title: 'Farmacias', icon: 'mdi-pharmacy', link: '/mod-pharmaceutic'},
            {title: 'Laboratorios', icon: 'mdi-eyedropper', link: '/mod-laboratories'},
            {title: 'Restaurantes', icon: 'mdi-silverware-fork-knife', link: '/mod-restaurants'},
            {title: 'Lugares', icon: 'mdi-ticket', link: '/mod-leisures'},
            {title: 'Hoteles', icon: 'mdi-bed', link: '/mod-hotels'},
            {title: 'Casas Recup.', icon: 'mdi-home', link: '/mod-houses'},
            {title: 'Transportes', icon: 'mdi-taxi', link: '/mod-transports'},
            {title: 'Viñedos', icon: 'mdi-tree', link: '/mod-vineyards'},
            {title: 'Blogs', icon: 'mdi-blogger', link: '/mod-blogs'},
            {title: 'Alianzas', icon: 'mdi-share-variant', link: '/mod-alliances'},
        ],
    },
];
